<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'statistics:review:dele'"
        >删除</a-button
      >
      <a-button
        v-if="selectionRows.length && editText == '保存'"
        type="default"
        class="mr-3"
        @click="cancel"
        v-has="'statistics:review:edit'"
        >取消</a-button
      >
      <a-button type="primary" v-has="'statistics:review:edit'" class="mr-3" @click="enterEdit">{{
        editText
      }}</a-button>
      <a-button
        type="danger"
        :loading="btnLoading"
        v-has="'statistics:review:reject'"
        class="mr-3"
        @click="rejectRating"
        >驳回</a-button
      >
      <a-button
        type="primary"
        :loading="btnLoading"
        v-if="selectedRowKeys.length"
        v-has="'statistics:review:confirm'"
        class="mr-3"
        @click="confirmRating"
        >确认</a-button
      >
    </div>
    <div class="table-page-search-wrapper" v-has="'statistics:review:search'" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQueryForm">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input-search
              style="width: 200px"
              @search="searchQueryForm"
              v-model="productionName"
              placeholder="请输入作品名称"
            />
          </a-col>
          <a-col class="filter-menu">
            <a-popover
              placement="bottom"
              v-model="popoverVis"
              overlayClassName="filter-pop"
              @click="getFilterTypes"
              trigger="click"
            >
              <template slot="content">
                <a-spin :spinning="filterLoading">
                  <a-collapse v-model="activeKey" style="font-size:12px;" :bordered="false">
                    <template #expandIcon="props">
                      <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                    </template>
                    <a-collapse-panel key="1" header="我的收藏">
                      <template v-for="(item, index) in collectList">
                        <p :key="index" v-if="item.isCollect">
                          <span class="star-span">
                            <a-icon v-if="!item.isCollect" @click="starClick(item)" type="star" />
                            <a-icon
                              v-else
                              @click="starClick(item)"
                              :style="'color:' + color"
                              type="star"
                              theme="filled"
                            />
                          </span>
                          <span class="filter-push-span" @click="filterListPush(item)">{{ item.label }}</span>
                        </p>
                      </template>
                    </a-collapse-panel>
                    <a-collapse-panel key="2" header="添加自定义筛选" :disabled="false">
                      <div v-for="(item, index) in addFilterList" :key="3 + index">
                        <p style="padding-left:40px;">
                          <select
                            v-model="item.filterTitle"
                            @change="
                              e => {
                                filterSelectChange(e, item)
                              }
                            "
                            style="width:120px"
                            placeholder="请选择"
                          >
                            <option value="">请选择筛选字段</option>
                            <option v-for="option in filterOrGroupOptions" :key="option.value" :value="option.value">{{
                              option.label
                            }}</option>
                          </select>
                          <span
                            v-if="addFilterList.length > 1"
                            @click="deleFilterList(index)"
                            style="margin-left:10px;font-size:20px"
                            >×</span
                          >
                        </p>
                        <p style="padding-left:40px;" v-if="getFilterType(item) != 'ENUM'" placeholder="请选择">
                          <select v-model="item.computeType" style="width:120px">
                            <option value="">请选择筛选方式</option>
                            <option :key="'eq'" :value="'eq'">等于</option>
                            <option :key="'ne'" :value="'ne'">不等于</option>
                            <option :key="'ct'" :value="'ct'">包含</option>
                            <option :key="'nk'" :value="'nk'">不包含</option>
                            <option v-if="getFilterType(item) != 'STRING'" :key="'bt'" :value="'bt'">介于</option>
                          </select>
                        </p>
                        <p style="padding-left:40px;" v-if="getFilterType(item)">
                          <input
                            style="width:120px"
                            v-if="getFilterType(item) == 'STRING'"
                            v-model="item.filterValue"
                            placeholder="请输入文本"
                          />
                          <input
                            style="width:120px"
                            v-if="getFilterType(item) == 'NUMBER' && item.computeType != 'bt'"
                            v-model="item.filterValue"
                            placeholder="请输入数值"
                          />
                          <input
                            style="width:120px"
                            v-if="getFilterType(item) == 'NUMBER' && item.computeType == 'bt'"
                            v-model="item.filterBtValue.start"
                            placeholder="起始数值"
                          />
                          <br v-if="getFilterType(item) == 'NUMBER' && item.computeType == 'bt'" />
                          <input
                            style="width:120px;margin-top:5px;"
                            v-if="getFilterType(item) == 'NUMBER' && item.computeType == 'bt'"
                            v-model="item.filterBtValue.end"
                            placeholder="结束数值"
                          />
                          <select v-if="getFilterType(item) == 'ENUM'" v-model="item.filterValue" style="width:120px">
                            <option value="">请选择类型</option>
                            <option :key="i.value" :value="i.value" v-for="i in item.filterOptions">{{
                              i.label
                            }}</option>
                          </select>
                        </p>
                      </div>
                    </a-collapse-panel>
                  </a-collapse>
                  <div
                    class="filter-div"
                    v-if="filterList.some(item => item.isFilter)"
                    style="padding-left:0;padding-bottom:0;border-bottom: 1px solid #d9d9d9;"
                  >
                    <template v-for="item in filterList">
                      <p v-if="item.isFilter" :key="item.label">
                        <span class="star-span">
                          <a-icon v-if="!item.isCollect" @click="starClick(item)" type="star" />
                          <a-icon
                            v-else
                            @click="starClick(item)"
                            :style="'color:' + color"
                            type="star"
                            theme="filled"
                          />
                        </span>
                        <span
                          @click="
                            () => {
                              item.isSelect = !item.isSelect
                            }
                          "
                          >{{ item.label }}</span
                        >
                        <a-icon
                          v-if="item.isSelect"
                          @click="
                            () => {
                              item.isSelect = !item.isSelect
                            }
                          "
                          :style="'margin-left:10px;color:' + color"
                          type="check"
                        />
                      </p>
                    </template>
                  </div>
                  <div class="filter-div" style="display: flex; justify-content: space-between;min-width:190px">
                    <span class="add-filter" @click="addFilter">+ 添加条件</span>
                    <div>
                      <button
                        class="add-filter-btn"
                        style="color:rgba(0,0,0,0.65);margin-right:5px"
                        @click="removeFilter"
                      >
                        重置
                      </button>
                      <button
                        class="add-filter-btn"
                        :style="'color: #fff;background-color:' + color"
                        @click="saveFilter"
                      >
                        应用
                      </button>
                    </div>
                  </div>
                </a-spin>
              </template>
              <a-icon
                type="filter"
                :style="filterList.filter(item => item.isSelect).length ? `color:${color}` : ''"
              /><span style="margin-left:3px">筛选</span>
            </a-popover>
          </a-col>
          <a-col class="filter-menu">
            <group
              :filterOrGroupOptions="filterOrGroupOptions"
              @setGroupBy="setGroupBy"
              ref="group"
              domain="review"
              columnsUrl="/difficultyFactor/fieldInfo"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <v-table
      ref="newTable"
      tableType="reviewTable"
      :queryParamProp="queryParam"
      :selectedRowKeysprop="selectedRowKeys"
      :url="url"
      :tablePage="tablePage"
      @openModal="openModal"
      :groupBy="groupBy"
      @setTablePage="setTablePage"
      @getTableFilterData="getTableFilterData"
      :columns="columns"
      @setRowkeys="setRowkeys"
    />
    <div v-if="!groupBy.length" style="display:flex;justify-content: end;z-index:99999">
      <a-pagination
        show-quick-jumper
        show-size-changer
        v-model="tablePage.currentPage"
        :page-size-options="['10', '20', '30', '40', '50']"
        :default-current="1"
        :defaultPageSize="50"
        :total="tablePage.totalResult"
        @showSizeChange="showSizeChange"
        @change="handlePageChange"
      />
    </div>
    <review-modal :open="drawerShow" :cur-row="curRow" :isForm="isNewForm" @closed="drawerClosed"></review-modal>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import reviewModal from './modules/review-modal.vue'
import { getAction, putAction, postAction } from '@/api/manage'
import { batchStyleInsertHeader, filterObj, handleArrayParams } from '@/utils/util'
import vTable from '@/components/v-table/vTable'
import group from '@/components/group/index'
import { deleteAction } from '../../api/manage'
export default {
  mixins: [JeecgListMixin],
  components: {
    reviewModal,
    vTable,
    group
  },
  data() {
    return {
      addFilterList: [
        {
          filterValue: '',
          filterTitle: '',
          computeType: '',
          filterBtValue: {
            start: '',
            end: ''
          },
          filterOptions: []
        }
      ],
      filterValue: '',
      filterTitle: '',
      computeType: '',
      filterTypes: [],
      filterBtValue: {
        start: '',
        end: ''
      },
      filterList: [],
      collectList: [],
      activeKey: ['1', '2'],
      filterLoading: false,
      popoverVis: false,
      groupBy: [],
      columns: [
        {
          title: '平台名称',
          dataIndex: 'platformName',
          checked: true,
          isNum: false
        },
        {
          title: '作品序号',
          dataIndex: 'productOrder',
          checked: true,
          width: 120,
          isNum: false
        },
        {
          title: '作品名称',
          dataIndex: 'productName',
          checked: true,
          isNum: false
        },
        {
          title: '系数分类',
          dataIndex: 'type',
          width: 120,
          checked: true,
          isNum: false
        },
        {
          title: '难度系数',
          dataIndex: 'value',
          checked: true,
          isNum: false
        },
        {
          title: '评审人员',
          dataIndex: 'creatorName',
          checked: true,
          isNum: false
        },
        /*         {
          title: '评审月份',
          dataIndex: 'date',
          checked: true,
          isNum: false
        }, */
        {
          title: '状态',
          dataIndex: 'status',
          checked: true,
          isNum: false,
          width: 200
        }
      ],
      btnLoading: false,
      tablePage: {
        currentPage: 1,
        pageSize: 50,
        totalResult: 0
      },
      positionList: [],
      translateUsers: [],
      oldDataSource: [],
      monthFormat: 'YYYY-MM',
      productionName: '',
      editText: '编辑',
      url: {
        list: '/difficultyFactor/beanSearcher'
      },
      color: '',
      allFilterList: '',
      filterOrGroupOptions: [
        { value: 'productName', label: '作品名称' },
        { value: 'platformName', label: '平台名称' },
        { value: 'productOrder', label: '作品序号' },
        { value: 'type', label: '系数分类' },
        { value: 'value', label: '难度系数' },
        { value: 'creatorName', label: '评审人员' },
        { value: 'status', label: '状态' }
      ]
    }
  },
  mounted() {
    setTimeout(() => {
      this.color = this.$store.state.app.color
    }, 50)
    this.getTableFilterData()
  },
  methods: {
    batchDel: function() {
      let that = this
      if (!this.selectedRowKeys.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction(
            '/difficultyFactor?' + new URLSearchParams(handleArrayParams({ ids: that.selectedRowKeys })).toString()
          )
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.getTableFilterData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    setGroupBy(groupBy) {
      this.$refs.newTable.filters.gexpr = ''
      this.queryParam.gexpr = ''
      this.filterList?.forEach((item, index) => {
        if (item.isSelect) {
          if (item.computeType != 'bt') {
            this.queryParam[index + '.' + item.filterTitle] = item.filterValue
            this.queryParam[index + '.' + item.filterTitle + '-op'] = item.computeType
          } else {
            this.queryParam[index + '.' + item.filterTitle + '-0'] = item.filterBtValue.start
            this.queryParam[index + '.' + item.filterTitle + '-1'] = item.filterBtValue.end
            this.queryParam[index + '.' + item.filterTitle + '-op'] = item.computeType
          }
          if (!index) {
            this.queryParam.gexpr = index
          } else {
            this.queryParam.gexpr = this.queryParam.gexpr + '&' + index
          }
        }
      })
      if (this.queryParam.gexpr[0] == '&') {
        this.queryParam.gexpr = this.queryParam.gexpr.slice(1)
      }
      this.$refs.newTable.filters.gexpr = this.queryParam.gexpr
      this.groupBy.forEach(item => {
        this.$refs.newTable.showColumn(item)
      })
      this.groupBy = groupBy
      this.$refs.newTable.filters.groupBy = this.groupBy[0]
      if (this.groupBy.length) {
        this.queryParam.groupBy = this.groupBy[0]
        this.$refs.newTable.loadData()
        this.groupBy.forEach(item => {
          this.$refs.newTable.hiddenColumn(item)
        })
      } else {
        this.$refs.newTable.loadData()
      }
    },
    async getTableFilterData() {
      const rest = await getAction('/fontQueryCondition', { domain: 'review', type: 'FILTER' })
      let arr = rest.data?.map(item => {
        return JSON.parse(item.content).computeType != 'bt'
          ? {
              label: item.name,
              filterTitle: JSON.parse(item.content).filterTitle,
              filterValue: JSON.parse(item.content).filterValue,
              computeType: JSON.parse(item.content).computeType,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
          : {
              label: item.name,
              filterTitle: JSON.parse(item.content).filterTitle,
              filterBtValue: JSON.parse(item.content).filterBtValue,
              computeType: JSON.parse(item.content).computeType,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
      })
      this.filterList = arr.filter(item => item.isFilter)
      const res = await getAction('/fontQueryCondition', { domain: 'review', type: 'GROUP' })
      let arrGroup = res.data?.map(item => {
        return JSON.parse(item.content).computeType != 'bt'
          ? {
              label: item.name,
              value: JSON.parse(item.content).value,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
          : {
              label: item.name,
              value: JSON.parse(item.content).value,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
      })
      let filterList = arrGroup.filter(item => item.isFilter)
      this.$refs.group.filterList = filterList
      let groupBy = []
      filterList.forEach((item, index) => {
        if (item.isSelect) {
          groupBy.push(item.value)
        }
      })
      this.setGroupBy(groupBy)
    },
    deleFilterList(index) {
      this.addFilterList.splice(index, 1)
    },
    async removeFilter() {
      this.filterLoading = true
      this.addFilterList = [
        {
          filterValue: '',
          filterTitle: '',
          computeType: '',
          filterBtValue: {
            start: '',
            end: ''
          },
          filterOptions: []
        }
      ]
      this.filterList.forEach(item => {
        item.isFilter = false
      })
      const data = this.filterList.map(item => {
        return {
          name: item.label,
          content: JSON.stringify(
            item.computeType != 'bt'
              ? {
                  filterTitle: item.filterTitle,
                  filterValue: item.filterValue,
                  computeType: item.computeType,
                  isSelect: item.isSelect,
                  isCollect: item.isCollect,
                  isFilter: item.isFilter
                }
              : {
                  filterTitle: item.filterTitle,
                  filterBtValue: item.filterBtValue,
                  computeType: item.computeType,
                  isSelect: item.isSelect,
                  isCollect: item.isCollect,
                  isFilter: item.isFilter
                }
          ),
          id: item.id || null,
          domain: 'review',
          type: 'FILTER'
        }
      })
      let arr = this.collectList.filter(item => !item.isCollect)
      this.filterList = []
      let ids = arr.map(item => item.id)
      await postAction('/fontQueryCondition', data)
      await deleteAction('/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids })).toString())
      this.collectList = this.collectList.filter(item => item.isCollect)
      this.filterLoading = false
    },
    filterListPush(item) {
      item.isSelect = true
      item.isFilter = true
      if (this.filterList.findIndex(c => c.label == item.label) == '-1') {
        this.filterList.push(item)
      }
    },
    getFilterTitle(filterTitle) {
      let label = ''
      switch (filterTitle) {
        case 'productName':
          label = '作品名称'
          break
        case 'platformName':
          label = '平台名称'
          break
        case 'productOrder':
          label = '作品序号'
          break
        case 'type':
          label = '系数分类为'
          break
        case 'value':
          label = '难度系数'
          break
        case 'creatorName':
          label = '评审人员'
          break
        case 'status':
          label = '状态为'
          break
      }
      return label
    },
    async saveFilter() {
      this.filterLoading = true
      this.addFilterList.forEach(item => {
        let label = ''
        switch (item.filterTitle) {
          case 'productName':
            label = '作品名称'
            break
          case 'platformName':
            label = '平台名称'
            break
          case 'productOrder':
            label = '作品序号'
            break
          case 'type':
            label = '系数分类为'
            break
          case 'value':
            label = '难度系数'
            break
          case 'creatorName':
            label = '评审人员'
            break
          case 'status':
            label = '状态为'
            break
        }
        switch (item.computeType) {
          case 'eq':
            label = label + '等于'
            break
          case 'ne':
            label = label + '不等于'
            break
          case 'ct':
            label = label + '包含'
            break
          case 'nk':
            label = label + '不包含'
            break
          case 'bt':
            label = label + '介于'
            break
        }
        if (!label) return
        if (item.computeType != 'bt') {
          if (item.filterTitle == 'status') {
            label = label + '“' + this.getStatusText({ status: item.filterValue }) + '”'
          } else if (item.filterTitle == 'type') {
            label =
              label +
              '“' +
              (item.filterValue == 'TRANSLATOR' ? '翻译' : item.filterValue == 'BUILDER' ? '制作' : '') +
              '”'
          } else {
            label = label + '“' + item.filterValue + '”'
          }
          if (this.filterList.every(i => i.label != label)) {
            if (item.id) {
              this.filterList.splice(
                this.filterList.findIndex(i => i.id == item.id),
                1
              )
            }
            this.filterList.push({
              label,
              filterValue: item.filterValue,
              filterTitle: item.filterTitle,
              computeType: item.computeType,
              id: item.id || null,
              isCollect: false,
              isSelect: true,
              isFilter: true
            })
          }
        } else {
          label = label + '“' + item.filterBtValue.start + '~' + item.filterBtValue.end + '”'
          if (this.filterList.every(i => i.label != label)) {
            if (item.id) {
              this.filterList.splice(
                this.filterList.findIndex(i => i.id == item.id),
                1
              )
            }
            this.filterList.push({
              label,
              filterValue: item.filterValue,
              filterTitle: item.filterTitle,
              computeType: item.computeType,
              id: item.id || null,
              isCollect: false,
              isSelect: true,
              isFilter: true
            })
          }
        }
      })
      this.queryParam = {}
      let data = this.filterList.map(item => {
        return {
          name: item.label,
          content: JSON.stringify(
            item.computeType != 'bt'
              ? {
                  filterTitle: item.filterTitle,
                  filterValue: item.filterValue,
                  computeType: item.computeType,
                  isSelect: item.isSelect,
                  isCollect: item.isCollect,
                  isFilter: item.isFilter
                }
              : {
                  filterTitle: item.filterTitle,
                  filterBtValue: item.filterBtValue,
                  computeType: item.computeType,
                  isSelect: item.isSelect,
                  isCollect: item.isCollect,
                  isFilter: item.isFilter
                }
          ),
          id: item.id || null,
          domain: 'review',
          type: 'FILTER'
        }
      })
      let deleArr = []
      this.allFilterList.forEach(item => {
        if (
          data.every(i => {
            return i.id != item.id && !item.isCollect && !item.isSelect
          })
        ) {
          deleArr.push(item.id)
        }
      })
      if (
        this.allFilterList.filter(function(item) {
          return data.findIndex(i => item.label == i.label) > -1
        }).length == 0
      ) {
        await postAction('/fontQueryCondition', data)
      }
      deleArr.length &&
        (await deleteAction(
          '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: deleArr })).toString()
        ))
      this.queryParam.gexpr = ''
      this.filterList.forEach((item, index) => {
        if (item.isSelect) {
          if (item.computeType != 'bt') {
            this.queryParam[index + '.' + item.filterTitle] = item.filterValue
            this.queryParam[index + '.' + item.filterTitle + '-op'] = item.computeType
          } else {
            this.queryParam[index + '.' + item.filterTitle + '-0'] = item.filterBtValue.start
            this.queryParam[index + '.' + item.filterTitle + '-1'] = item.filterBtValue.end
            this.queryParam[index + '.' + item.filterTitle + '-op'] = item.computeType
          }
          if (!index) {
            this.queryParam.gexpr = index
          } else {
            this.queryParam.gexpr = this.queryParam.gexpr + '&' + index
          }
        }
      })
      if (this.queryParam.gexpr[0] == '&') {
        this.queryParam.gexpr = this.queryParam.gexpr.slice(1)
      }
      this.addFilterList = [
        {
          filterValue: '',
          filterTitle: '',
          computeType: '',
          filterBtValue: {
            start: '',
            end: ''
          },
          filterOptions: []
        }
      ]
      this.tablePage.currentPage = 1
      let groupBy = []
      this.$refs.group.filterList.forEach((item, index) => {
        if (item.isSelect) {
          groupBy.push(item.value)
        }
      })
      this.setGroupBy(groupBy)
      this.filterLoading = false
      this.popoverVis = false
    },
    async starClick(item) {
      item.isCollect = !item.isCollect
      if (this.collectList.findIndex(c => c.label == item.label) == '-1' && item.isCollect) {
        this.collectList.push(item)
        const data = [
          {
            name: item.label,
            content: JSON.stringify(
              item.computeType != 'bt'
                ? {
                    filterTitle: item.filterTitle,
                    filterValue: item.filterValue,
                    computeType: item.computeType,
                    isSelect: true,
                    isCollect: true,
                    isFilter: item.isFilte
                  }
                : {
                    filterTitle: item.filterTitle,
                    filterBtValue: item.filterBtValue,
                    computeType: item.computeType,
                    isSelect: true,
                    isCollect: true,
                    isFilter: item.isFilter
                  }
            ),
            domain: 'review',
            type: 'FILTER'
          }
        ]
        await postAction('/fontQueryCondition', data)
        await deleteAction(
          '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: [item.id || ''] })).toString()
        )
      } /* else if(this.collectList.findIndex(c=>c.label==item.label)!='-1'&&!item.isCollect){
        this.collectList.splice(this.collectList.findIndex(c=>c.label==item.label),1)
      } */
      if (!item.isCollect) {
        let ids = [item.id]
        await deleteAction('/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids })).toString())
      }
    },
    addFilter() {
      this.addFilterList.push({
        filterValue: '',
        filterTitle: '',
        computeType: '',
        filterBtValue: {
          start: '',
          end: ''
        },
        filterOptions: []
      })
    },
    async filterSelectChange(e, item) {
      item.filterOptions = []
      switch (e.target.value) {
        case 'type':
          const typeRes = await getAction('/difficultyFactor/condition?property=type')
          item.filterOptions = typeRes.data.map(item => {
            return { label: item == 'TRANSLATOR' ? '翻译' : item == 'BUILDER' ? '制作' : '', value: item }
          })
          break
        case 'status':
          const statusRes = await getAction('/difficultyFactor/condition?property=status')
          item.filterOptions = statusRes.data.map(item => {
            return { label: this.getStatusText({ status: item }), value: item }
          })
          break
      }
      this.$forceUpdate()
    },
    async getFilterTypes() {
      this.filterLoading = true
      this.collectList = []
      this.filterTypes = []
      const res = await getAction('/difficultyFactor/fieldInfo')
      for (let key in res.data) {
        this.filterTypes.push({ value: key, type: res.data[key] })
      }
      const rest = await getAction('/fontQueryCondition', { domain: 'review', type: 'FILTER' })
      let arr = rest.data?.map(item => {
        return JSON.parse(item.content).computeType != 'bt'
          ? {
              label: item.name,
              filterTitle: JSON.parse(item.content).filterTitle,
              filterValue: JSON.parse(item.content).filterValue,
              computeType: JSON.parse(item.content).computeType,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
          : {
              label: item.name,
              filterTitle: JSON.parse(item.content).filterTitle,
              filterBtValue: JSON.parse(item.content).filterBtValue,
              computeType: JSON.parse(item.content).computeType,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
      })
      this.allFilterList = arr
      this.collectList = arr.filter(function(item, index) {
        return arr.findIndex(i => item.label == i.label) === index && item.isCollect
      })
      this.filterList = arr.filter(item => item.isFilter)
      this.addFilterList = arr.filter(item => item.isFilter).length
        ? arr.filter(item => item.isFilter)
        : [
            {
              filterValue: '',
              filterTitle: '',
              computeType: '',
              filterBtValue: {
                start: '',
                end: ''
              },
              filterOptions: []
            }
          ]
      this.addFilterList?.forEach(item => {
        this.filterSelectChange({ target: { value: item.filterTitle } }, item)
      })
      this.filterLoading = false
    },
    getFilterType(i) {
      let type = ''
      if (
        this.filterTypes[
          this.filterTypes.findIndex(item => {
            return item.value == i.filterTitle
          })
        ]
      ) {
        type = this.filterTypes[
          this.filterTypes.findIndex(item => {
            return item.value == i.filterTitle
          })
        ].type
      }
      return type
    },
    getStatusText(record) {
      switch (record.status) {
        case 'REVIEW_FINISH':
          return 'BOSS已确认'
        case 'REVIEW_CONFIRM':
          return 'HR已确认'
        case 'REVIEW_REJECT':
          return 'BOSS已驳回'
        case 'REVIEW_SUBMIT':
          return '审核岗已确认'
        case 'REVIEW_SUBMIT_AUTO':
          return '自动推送'
        case 'PREPARE_REJECT_HR':
          return 'HR已驳回'
        case 'PREPARE_REJECT':
          return '校对/监制驳回'
        case 'PREPARE_CONFIRM':
          return '普通岗已确认'
      }
    },
    setTablePage(total) {
      if (total) {
        this.tablePage.totalResult = total - 0
      } else {
        this.tablePage.totalResult = 0
      }
    },
    showSizeChange(e, num) {
      this.tablePage.pageSize = num
      this.getTableFilterData()
    },
    handlePageChange() {
      this.getTableFilterData()
    },
    drawerClosed(type) {
      this.drawerShow = false
      this.isAdd = true
      if (type) {
        this.editText = '编辑'
        this.loadData()
        this.getTableFilterData()
      }
      this.getTableFilterData()
    },
    async enterEdit() {
      if (this.selectionRows.length) {
        if (this.editText == '编辑') {
          this.editText = '保存'
        } else {
          this.editText = '编辑'
        }
        let isEdit = false
        this.selectionRows.forEach(item => {
          if (item.editable == true) {
            isEdit = false
          } else {
            isEdit = true
          }
        })
        if (!isEdit) {
          const res = await postAction(
            '/difficultyFactor',
            this.selectionRows.filter(item => !item.groupCount)
          )
          if (res.code == 200) {
            this.$message.success('保存成功')
            this.selectedRowKeys.forEach(item => {
              this.$refs.newTable.dataSource.forEach(one => {
                if (one.id == item) {
                  one.editable = false
                }
              })
              this.changeEditbleFalse(this.$refs.newTable.dataSource, true, item)
              this.oldDataSource = JSON.parse(JSON.stringify(this.$refs.newTable.dataSource))
            })
            this.$refs.newTable.updateData()
            this.$refs.newTable.clearSelectKeys()
          } else {
            this.$message.error(res.msg || res.message)
          }
          /* this.$refs.newTable.loadData() */
        } else {
          this.selectedRowKeys.forEach(item => {
            this.$refs.newTable.dataSource.forEach(one => {
              if (one.id == item) {
                one.editable = true
              }
            })
            this.changeEditble(this.$refs.newTable.dataSource, true, item)
            this.oldDataSource = JSON.parse(JSON.stringify(this.$refs.newTable.dataSource))
          })
          this.$refs.newTable.updateData()
        }
      } else {
        this.$message.warning('请先勾选数据')
      }
    },
    changeEditbleFalse(arr, hasChild, id) {
      arr?.forEach(item => {
        if (item.id == id) {
          item.editable = false
        } else {
          this.changeEditbleFalse(item.children, item.hasChild, id)
        }
      })
    },
    changeEditble(arr, hasChild, id) {
      arr?.forEach(item => {
        if (item.id == id) {
          item.editable = true
        } else {
          this.changeEditble(item.children, item.hasChild, id)
        }
      })
    },
    cancel() {
      if (this.selectionRows.length) {
        let self = this
        if (this.oldDataSource.length > 0) {
          if (JSON.stringify(this.oldDataSource) != JSON.stringify(this.$refs.newTable.dataSource)) {
            this.$confirm({
              title: '提示',
              content: '您尚未保存数据，需要保存吗？',
              async onOk() {
                self.$refs.newTable.dataSource.forEach(item => {
                  item.editable = false
                })
                const res = await postAction(
                  '/difficultyFactor',
                  self.selectionRows.filter(item => !item.groupCount)
                )
                if (res.code == 200) {
                  self.$message.success('保存成功')
                  self.selectedRowKeys.forEach(item => {
                    self.$refs.newTable.dataSource.forEach(one => {
                      if (one.id == item) {
                        one.editable = false
                      }
                    })
                    self.changeEditbleFalse(self.$refs.newTable.dataSource, true, item)
                    self.oldDataSource = JSON.parse(JSON.stringify(self.$refs.newTable.dataSource))
                  })
                  self.$refs.newTable.updateData()
                  self.$refs.newTable.clearSelectKeys()
                  self.editText = '编辑'
                } else {
                  self.$message.error(res.msg)
                }
              },
              onCancel() {
                self.editText = '编辑'
                self.selectedRowKeys.forEach(item => {
                  self.$refs.newTable.dataSource.forEach(one => {
                    if (one.id == item) {
                      one.editable = false
                    }
                  })
                  self.changeEditbleFalse(self.$refs.newTable.dataSource, true, item)
                  self.oldDataSource = JSON.parse(JSON.stringify(self.$refs.newTable.dataSource))
                })
                self.$refs.newTable.updateData()
                self.$refs.newTable.clearSelectKeys()
                self.getTableFilterData()
              }
            })
          } else {
            this.editText = '编辑'
            this.$refs.newTable.clearSelectKeys()
            this.$refs.newTable.dataSource.forEach(one => {
              one.editable = false
            })
          }
        }
      } else {
        this.$message.warning('请先勾选数据')
      }
    },
    async confirmRating() {
      this.btnLoading = true
      let list = []
      this.selectionRows
        .filter(item => !item.groupCount)
        .forEach(item => {
          list.push({
            operation: 'DOWN',
            version: item.version,
            id: item.id
          })
        })
      if (this.selectionRows.length) {
        const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
        if (res.success) {
          this.$refs.newTable.clearSelectKeys()
          this.getTableFilterData()
          this.btnLoading = false
          this.$message.success('已确认')
        } else {
          this.btnLoading = false
          this.$message.error(res.msg)
        }
      } else {
        this.btnLoading = false
        this.$message.warning('请先勾选数据')
      }
    },

    async rejectRating() {
      this.btnLoading = true
      let list = []
      this.selectionRows
        .filter(item => !item.groupCount)
        .forEach(item => {
          list.push({
            operation: 'UP',
            version: item.version,
            id: item.id
          })
        })
      if (this.selectionRows.length) {
        const res = await putAction('/v2/difficultyFactor/status/process/batch', list)
        if (res.success) {
          this.$refs.newTable.clearSelectKeys()
          this.getTableFilterData()
          this.btnLoading = false
          this.$message.success('驳回成功')
        } else {
          this.btnLoading = false
          this.$message.error(res.msg)
        }
      } else {
        this.btnLoading = false
        this.$message.warning('请先勾选数据')
      }
    },
    setRowkeys(records) {
      records = records.filter(item => !item.hasChild)
      this.selectedRowKeys = records.map(item => {
        return item.id
      })
      this.selectionRows = records
      console.log(this.selectedRowKeys)
    },
    openModal(record) {
      this.openDetail(record)
    },
    searchQueryForm() {
      this.queryParam['top.productName'] = this.productionName
      this.queryParam['top.productName-op'] = 'ct'
      if (this.queryParam.gexpr) {
        this.queryParam.gexpr = this.queryParam.gexpr + '&top'
      } else {
        this.queryParam.gexpr = 'top'
      }
      this.$refs.newTable.loadData()
    },
    getAllPosition() {
      getAction('/sys/position/list').then(res => {
        if (res.success) {
          this.positionList = res.data.records
          this.positionList.forEach(one => {
            if (one.name == '翻译') {
              this.getAllTranslateUsers(one.id)
            }
          })
        }
      })
    },
    getAllTranslateUsers(postId) {
      getAction('/sys/user/query_by_position', {
        positionId: postId
      }).then(({ data }) => {
        this.translateUsers = data
      })
    },

    manualRate() {
      let ids = this.selectedRowKeys.join(',')
      putAction('/rating/manual_rate?ids=' + ids).then(({ success, data }) => {
        if (success) {
          this.loadData()
        }
      })
    },

    sendEmail() {
      postAction('/rating/send_email').then(({ success, message, msg }) => {
        if (success) {
          this.$message.success('发送成功')
          this.loadData()
        } else {
          this.$message.error(message || msg)
        }
      })
    }
  },
  created() {
    this.getAllPosition()
  },

  computed: {
    allowSendEmailShow() {
      let allow = true
      if (this.dataSource) {
        this.dataSource.forEach(one => {
          if (one.firstStagePass) {
            allow = false
          }
        })
      }
      return allow
    }
  }
}
</script>

<style lang="less" scoped>
.filter-menu {
  display: flex;
  align-items: center;
  i:hover {
    cursor: pointer;
  }
}
.red {
  color: red;
}
/deep/ .ant-calendar-picker-input::-webkit-input-placeholder {
  font-size: 14px !important;
}
/deep/ .ant-calendar-picker-input::-moz-input-placeholder {
  font-size: 14px !important;
}

/deep/ .ant-calendar-picker-input::-ms-input-placeholder {
  font-size: 14px !important ;
}
</style>
