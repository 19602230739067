<template>
  <a-popover placement="bottom" v-model="popoverVis" overlayClassName="filter-pop" @click="getColumns" trigger="click">
    <template slot="content">
      <a-spin :spinning="filterLoading">
        <a-collapse v-model="activeKey" style="font-size:12px;" :bordered="false">
          <template #expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel key="1" header="我的收藏">
            <template v-for="(item, index) in collectList">
              <p :key="index" v-if="item.isCollect">
                <span class="star-span">
                  <a-icon v-if="!item.isCollect" @click="starClick(item)" type="star" />
                  <a-icon v-else @click="starClick(item)" :style="'color:' + color" type="star" theme="filled" />
                </span>
                <span class="filter-push-span" @click="filterListPush(item)">{{ item.label }}</span>
              </p>
            </template>
          </a-collapse-panel>
          <a-collapse-panel key="2" header="添加自定义分组" :disabled="false">
            <div v-for="(item, index) in addFilterList" :key="3 + index">
              <p style="padding-left:40px;">
                <select v-model="item.value" style="width:120px" placeholder="请选择">
                  <option value="">请选择分组字段</option>
                  <option v-for="option in filterOrGroupOptions" :value="option.value" :key="option.value">{{
                    option.label
                  }}</option>
                </select>
                <span v-if="index" @click="deleFilterList(index)" style="margin-left:10px;font-size:20px">×</span>
              </p>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <div
          class="filter-div"
          v-if="filterList.some(item => item.isFilter)"
          style="padding-left:0;padding-bottom:0;border-bottom: 1px solid #d9d9d9;"
        >
          <template v-for="item in filterList">
            <p v-if="item.isFilter" :key="item.label">
              <span class="star-span">
                <a-icon v-if="!item.isCollect" @click="starClick(item)" type="star" />
                <a-icon v-else @click="starClick(item)" :style="'color:' + color" type="star" theme="filled" />
              </span>
              <span
                @click="
                  () => {
                    item.isSelect = !item.isSelect
                  }
                "
                >{{ item.label }}</span
              >
              <a-icon
                v-if="item.isSelect"
                @click="
                  () => {
                    item.isSelect = !item.isSelect
                  }
                "
                :style="'margin-left:10px;color:' + color"
                type="check"
              />
            </p>
          </template>
        </div>
        <div class="filter-div" style="display: flex; justify-content: space-between;min-width:190px">
          <span class="add-filter" @click="addFilter">+ 添加条件</span>
          <div>
            <button class="add-filter-btn" style="color:rgba(0,0,0,0.65);margin-right:5px" @click="removeFilter">
              重置
            </button>
            <button class="add-filter-btn" :style="'color: #fff;background-color:' + color" @click="saveFilter">
              应用
            </button>
          </div>
        </div>
      </a-spin>
    </template>
    <div>
      <span
        class="iconfont icon-fenzu icon"
        style="color:rgba(0,0,0,0.65)"
        :style="filterList.filter(item => item.isSelect).length ? `color:${color}` : ''"
      ></span
      ><span style="margin-left:3px">分组</span>
    </div>
  </a-popover>
</template>

<script>
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
import { batchStyleInsertHeader, filterObj, handleArrayParams } from '@/utils/util'
export default {
  props: {
    columnsUrl: {
      type: String,
      default: ''
    },
    filterOrGroupOptions: {
      type: Array,
      default: () => []
    },
    domain: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      type: 'GROUP',
      popoverVis: false,
      filterLoading: false,
      columns: [],
      collectList: [],
      filterList: [],
      addFilterList: [{ value: '' }],
      color: '',
      allFilterList:[],
      activeKey:['1','2']
    }
  },
  mounted() {
    setTimeout(() => {
      this.color = this.$store.state.app.color
    }, 50)
  },
  methods: {
    async saveFilter() {
      this.filterLoading = true
      this.addFilterList.forEach(item => {
        if (!item.value) return
        console.log(this.filterList)
        let label = this.filterOrGroupOptions.filter(i => i.value == item.value)[0].label
        if(this.filterList.every(i => i.label != label)){
          if (item.id) {
            this.filterList.splice(
              this.filterList.findIndex(i => i.id == item.id),
              1
            )
          }
          this.filterList.push({
            label,
            value: item.value,
            id: item.id || null,
            isCollect: false,
            isSelect: true,
            isFilter: true
          })
        }
      })
      const data = this.filterList.map(item => {
        return {
          name: item.label,
          content: JSON.stringify({
            isSelect: item.isSelect,
            isCollect: item.isCollect,
            isFilter: item.isFilter,
            value: item.value
          }),
          id: item.id || null,
          domain: this.domain,
          type: 'GROUP'
        }
      })
      let deleArr = []
      this.allFilterList.forEach(item => {
        if (
          data.every(i => {
            return i.id != item.id && !item.isCollect && !item.isSelect
          })
        ) {
          deleArr.push(item.id)
        }
      })
      if (
        this.allFilterList.filter(function(item) {
          return data.findIndex(i => item.label == i.label) > -1
        }).length == 0
      ) {
        await postAction('/fontQueryCondition', data)
      }
      deleArr.length &&
        (await deleteAction(
          '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: deleArr })).toString()
        ))
      let groupBy = []
      this.filterList.forEach((item, index) => {
        if (item.isSelect) {
          groupBy.push(item.value)
        }
      })
      this.$emit('setGroupBy', groupBy)
      this.addFilterList = [{ value: '' }]
      this.filterLoading = false
      this.popoverVis = false
    },
    async removeFilter() {
      this.filterLoading = true
      this.addFilterList = [{ value: '' }]
      this.filterList.forEach(item => {
        item.isFilter = false
      })
      const data = this.filterList.map(item => {
        return {
          name: item.label,
          content: JSON.stringify({
            value: item.value,
            isSelect: item.isSelect,
            isCollect: item.isCollect,
            isFilter: item.isFilter
          }),
          id: item.id || null,
          domain: this.domain,
          type: 'GROUP'
        }
      })
      let arr = this.collectList.filter(item => !item.isCollect)
      this.filterList = []
      let ids = arr.map(item => item.id)
      await postAction('/fontQueryCondition', data)
      await deleteAction('/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids })).toString())
      this.collectList = this.collectList.filter(item => item.isCollect)
      this.filterLoading = false
    },
    deleFilterList(index) {
      this.addFilterList.splice(index, 1)
    },
    async getColumns() {
      this.filterLoading = true
      this.collectList = []
      this.columns = []
      const res = await getAction(this.columnsUrl)
      for (let key in res.data) {
        this.columns.push({ value: key, type: res.data[key] })
      }
      const rest = await getAction('/fontQueryCondition', { domain: this.domain, type: this.type })
      let arr = rest.data?.map(item => {
        return JSON.parse(item.content).computeType != 'bt'
          ? {
              label: item.name,
              value: JSON.parse(item.content).value,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
          : {
              label: item.name,
              value: JSON.parse(item.content).value,
              isCollect: JSON.parse(item.content).isCollect,
              isSelect: JSON.parse(item.content).isSelect,
              isFilter: JSON.parse(item.content).isFilter,
              id: item.id
            }
      })
      this.allFilterList = arr
      this.collectList = arr.filter(function(item, index) {
        return arr.findIndex(i => item.value == i.value) === index && item.isCollect
      })
      this.filterList = arr.filter(item => item.isFilter)
      this.addFilterList = arr.filter(item => item.isFilter).length
        ? arr.filter(item => item.isFilter)
        : [{ value: '' }]
      this.filterLoading = false
    },
    filterListPush(item) {
      item.isSelect = true
      item.isFilter = true
      if (this.filterList.findIndex(c => c.label == item.label) == '-1') {
        this.filterList.push(item)
      }
    },
    addFilter() {
      this.addFilterList.push({ value: '' })
    },
    async starClick(item) {
      item.isCollect = !item.isCollect
      if (this.collectList.findIndex(c => c.value == item.value) == '-1' && item.isCollect) {
        this.collectList.push(item)
        const data = [
          {
            name: item.label,
            content: JSON.stringify({ value: item.value, isSelect: true, isCollect: true, isFilter: item.isFilter }),
            domain: this.domain,
            type: 'GROUP'
          }
        ]
        await postAction('/fontQueryCondition', data)
        await deleteAction(
          '/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids: [item.id || ''] })).toString()
        )
      }
      if (!item.isCollect) {
        let ids = [item.id]
        await deleteAction('/fontQueryCondition?' + new URLSearchParams(handleArrayParams({ ids })).toString())
      }
    }
  }
}
</script>

<style lang="less" scoped>
i:hover {
  cursor: pointer;
}
.icon:hover {
  cursor: pointer;
}
</style>
